<template>
	<div>
			<v-row :align="'center'" class="mt-n3 pt-0">
				<v-col cols="4">
					<v-autocomplete
							v-model="postShop"
							:items="shops"
							:item-text="'name'"
							:item-value="'name'"
							return-object
							label="Магазин"
							placeholder="Магазин"
							outlined
					></v-autocomplete>
				</v-col>
				<v-col cols="4">
					<v-select
							v-model="postPartnerId"
							label="Партнёрка"
							placeholder="Партнёрка"
							outlined
							:items="partners"
							:item-text="'title'"
							:item-value="'id'"
					>
					</v-select>
				</v-col>
				<v-col cols="4">
					<v-select
							v-model="postChannel"
							label="Канал"
							placeholder="Канал"
							outlined
							multiple
							:items="channels"
							:item-text="'name'"
							:item-value="'id'"
					>
					</v-select>
				</v-col>
			</v-row>
			<v-row no-gutters align="center">
        <v-col cols="12">
          <v-text-field
            v-model="postTemplateTitle"
            placeholder="Название шаблона"
            label="Название шаблона"
            outlined
          >
          </v-text-field>
        </v-col>
				<v-col cols="12">
					<v-combobox
							v-model="postTitle"
							label="Заголовок поста"
							placeholder="Заголовок поста"
							outlined
							:items="searchItems"
							:search-input.sync="searchString"
							:item-text="'template_title'"
							:item-value="'title'"
							:loading="searchIsLoading"
							:return-object="false"
							no-filter
					>
						<template v-slot:item="data">
							<template v-if="typeof data.item !== 'object'">
								<v-list-item-content v-text="data.item"></v-list-item-content>

							</template>
							<template v-else>
								<v-list-item-content @click="selectProduct(data.item)">
									<v-list-item-title v-html="data.item.template_title"></v-list-item-title>
								</v-list-item-content>
                <v-list-item-action @click="selectProduct(data.item)">
                  <v-btn icon><v-icon>{{mdiArchiveSearch}}</v-icon></v-btn>
                </v-list-item-action>
							</template>
						</template>
						<template v-slot:no-data>
							<v-list-item>
								<v-list-item-content>
									Ничего не найдено.
								</v-list-item-content>
<!--								<v-list-item-action-text>-->
<!--									<v-btn v-if="!searchIsLoading" color="primary" @click="createProduct()">Создать товар</v-btn>-->
<!--								</v-list-item-action-text>-->
							</v-list-item>
						</template>
            <template v-slot:append-item>
              <v-list-item>
                <v-list-item-content>

                </v-list-item-content>
                <v-list-item-action>
                  <v-btn dense v-if="!searchIsLoading" color="primary" @click="createProduct()">Создать товар</v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
					</v-combobox>
				</v-col>
				<v-col cols="8">
					<v-textarea
							v-model="postSubtitle"
							align="right"
							label="Подзаголовок"
							:placeholder="'Подзаголовок'"
							outlined
							@change="postSubtitle = clean(postSubtitle)"
					></v-textarea>
				</v-col>
				<v-col cols="4">
					<div class="text-center mt-n5">
						<links ref="links" :links.sync="links" :partners="partners" :errors.sync="postErrors"></links>
					</div>

				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="3">
					<v-text-field
							v-model="postPromoCode"
							label="Промик"
							:placeholder="'Промик'"
							outlined
							@change="postPromoCode = cleanPromoCode(postPromoCode)"
					></v-text-field>
				</v-col>
				<v-col cols="3">
					<v-text-field
							v-model="postPrice"
							label="Цена итоговая"
							placeholder="Цена итоговая"
							outlined
							@change="postPrice = cleanPrice(postPrice)"
					>
					</v-text-field>
				</v-col>
				<v-col cols="3">
					<v-text-field
							v-model="postFullPrice"
							label="Цена без скидки"
							placeholder="Цена без скидки"
							outlined
							@change="postFullPrice = cleanPrice(postFullPrice)"
					>
					</v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters justify="center">
				<v-col cols="12">
					<v-text-field
							v-model="postAliLink"
							label="Ссылка на товар"
							:placeholder="'Ссылка на товар'"
							outlined
							@change="postAliLink = cleanLink(postAliLink)"
					></v-text-field>
				</v-col>

<!--					<div class="text-center mt-2"  v-if="postShop.id===1">-->
<!--						<v-btn-->
<!--								color="blue"-->
<!--								@click="getAERedirectLink()"-->
<!--						>Преобразовать ссылку (AEP)-->
<!--						</v-btn>-->
<!--					</div>-->
<!--					<div class="text-center mt-2"  v-else>-->
<!--						<v-btn-->
<!--								color="blue"-->
<!--								@click="getEPNRedirectLink()"-->
<!--						>Преобразовать ссылку (EPN)-->
<!--						</v-btn>-->
<!--						<v-btn-->
<!--								color="blue"-->
<!--								@click="getAdmitadRedirectLink()"-->
<!--						>Преобразовать ссылку (AdmitAD)-->
<!--						</v-btn>-->
<!--					</div>-->
<!--				</v-col>-->
				<v-col cols="12">
<!--					<v-text-field-->
<!--							v-model="postLink"-->
<!--							label="Ссылка волшебная"-->
<!--							:placeholder="'Ссылка волшебная'"-->
<!--							outlined-->
<!--							:readonly="linkLocked"-->
<!--							:loading="aliLinkLoading"-->
<!--							@change="postLink = cleanLink(postLink)"-->
<!--					></v-text-field>-->
				</v-col>
				<v-col cols="12">
					<v-textarea v-model="postChars"
											outlined
                      height="300"
											label="Характеристики"
											placeholder="Характеристики"
											@change="postChars = clean(postChars)">
					</v-textarea>
				</v-col>
				<v-col cols="12">
					<v-text-field
							v-model="postDescription"
							label="Описание"
							:placeholder="'Описание'"
							outlined
							@change="postDescription = clean(postDescription)"
					></v-text-field>
				</v-col>
				<v-col cols="9">
					<v-text-field
							v-model="postRating"
							label="Рейтинг"
							placeholder="'Рейтинг'"
							outlined
					></v-text-field>
        </v-col>
        <v-col cols="3">
          <rating :rating.sync="postRating"></rating>
        </v-col>
				<v-col cols="9">
					<v-text-field
							v-model="postWarranty"
							label="Гарантия"
							:placeholder="'Гарантия'"
							outlined
					>
          </v-text-field>
				</v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="warrantyCheckbox"
            label="Офиц. гарантия"
            @click="warrantyCheckbox ? postWarranty = 'Официальная гарантия.' : postWarranty = ''"
          >
          </v-checkbox>

        </v-col>
				<v-col cols="12">
					<v-text-field
							v-model="postInstruction"
							label="Инструкция"
							:placeholder="'Инструкция'"
							outlined
							@change="postInstruction = clean(postInstruction)"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="3" class="mt-n5">
					<div class="text-center">
						<v-btn width="90%" color="primary" justify="center" @click="postInstruction='✍️Для получения скидки необходимо использовать купон продавца (брать под ценой).'">Купон</v-btn>
					</div>
				</v-col>
				<v-col cols="3" class="mt-n5">
					<div class="text-center">
						<v-btn width="90%" color="primary" justify="center" @click="postInstruction='✍️Для получения скидки необходимо использовать промокод (вводить в корзине).'">Промик</v-btn>
					</div>
				</v-col>
				<v-col cols="3" class="mt-n5">
					<div class="text-center">
						<v-btn width="90%" color="primary" justify="center" @click="postInstruction='✍️Для получения скидки необходимо использовать купон продавца (брать под ценой) и промокод (вводить в корзине). ✍ Скидка автоматически применится в корзине'">Купон + промик</v-btn>
					</div>
				</v-col>
        <v-col cols="3" class="mt-n5">
          <div class="text-center">
            <v-btn width="90%" color="primary" justify="center" @click="postInstruction='✍️Скидка автоматически применится в корзине'">Автомат</v-btn>
          </div>
        </v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-text-field
							v-model="postDelivery"
							label="Доставка"
							:placeholder="'Доставка'"
							outlined
							@change="postDelivery = clean(postDelivery)"
					></v-text-field>
				</v-col>
				<v-col cols="12">
					<v-select
							v-model="postDelivery"
							:items="deliveryOptions"
							outlined
							placeholder="Доставка"
							label="Доставка"
					></v-select>
				</v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="postPrices.lowest"
            label="Самая низкая цена"
            :input-value="1"
          >

          </v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="postPrices.noName"
            outlined
            label="Ноунеймы МСК"
            placeholder="Ноунеймы МСК"
            @change="postPrices.noName = cleanPrice(postPrices.noName)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="postPrices.marketplaces"
            outlined
            label="Маркетплейсы РФ"
            placeholder="Маркетплейсы РФ"
            @change="postPrices.marketplaces = cleanPrice(postPrices.marketplaces)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="postPrices.bigs"
            outlined
            label="Крупные сетевые"
            placeholder="Крупные сетевые"
            @change="postPrices.bigs = cleanPrice(postPrices.bigs)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="postPrices.ozon"
            outlined
            label="Цена на OZON"
            placeholder="Цена на OZON"
            @change="postPrices.ozon = cleanPrice(postPrices.ozon)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="postPrices.yandex"
            outlined
            label="Яндекс.Маркет"
            placeholder="Яндекс.Маркет"
            @change="postPrices.yandex = cleanPrice(postPrices.yandex)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="postPrices.other"
            outlined
            label="Магазины РФ"
            placeholder="Магазины РФ"
            @change="postPrices.other = cleanPrice(postPrices.other)"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="postPrices.without_delivery"
            label="Без учёта доставки"
            :input-value="1"
          >
          </v-checkbox>
        </v-col>
			</v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="postErid"
            label="ERID"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="postAepCreativeId"
            label="ID креатива AEP"
            :disabled="shop.id > 2"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
			<v-row>
				<v-col v-if="id>0" cols="12">
					<v-img
              contain
							v-if="imageUrl"
							:key="imageKey"
							:src="'https://backend.telestatic.ru/upload/product_images/' + imageUrl"
							max-height="400"
							border="1"
					></v-img>
					<vue-dropzone
							v-else
							id="dropzone"
							ref="dropzone"
							class="mb-3"
							:options="dropzoneOptions"
							@vdropzone-success="uploadSuccess"
							@vdropzone-file-added="updateDZHeaders"
					></vue-dropzone>
				</v-col>
				<v-col v-if="id>0" cols="12">
					<v-text-field placeholder="Сюда вставлять картинку" label="Сюда вставлять картинку" outlined @paste="onPaste"></v-text-field>
				</v-col>
			</v-row>
			<v-row justify="center" align="center">
				<v-col v-if="id<1">
					<v-btn color="success" justify="center" @click="save()">Добавить продукт</v-btn>
        </v-col>
        <v-col v-else>
					<v-btn  color="info" justify="center" @click="save()">Сохранить продукт</v-btn>
        </v-col>
        <v-col v-if="id>0" >
					<v-btn color="info" @click="imageUrl=''">Обновить изображение</v-btn>
        </v-col>
        <v-col v-if="id>0">
					<v-btn  color="info" @click="requestScreenshot()">Сделать скриншот</v-btn>
        </v-col>
					<cropper v-if="id>0" :id="id" :key="imageUrl" @uploaded="reloadImage"></cropper>
				<v-col>
					<v-btn v-if="id>0" color="red" @click="copyProduct()">Создать копированием</v-btn>
				</v-col>
			</v-row>
	</div>
</template>

<script>
import {mdiArchiveSearch, mdiCheckCircle, mdiCreation} from '@mdi/js'
import _ from 'lodash'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Cropper from './Cropper'
import ShowProduct from '@/components/products/ShowProduct'
import httpClient from '../../services/http.service'

export default {
  name: 'EditProduct',
  components: {
    vueDropzone: vue2Dropzone,
    Cropper,
    Rating: () => import('./Rating'),
    Links: () => import('./Links'),
  },
  props:{
    templateTitle: String,
    title: String,
    subtitle: String,
    chars: String,
    description: String,
    promoCode: String,
    instruction: String,
    delivery: String,
    price: String,
    otherPrice: String,
    fullPrice: String,
    shop: Object,
    image: String,
    productId: Number,
    link: String,
    aliLink: String,
    finalText: String,
    postId: Number,
    rating: String,
    partnerId: Number,
    channel: Array,
    warranty: String,
    deadline: String,
    errors: Array,
    links: Array,
    prices: Object
  },
  data(){
    const token = localStorage.getItem('ACCESS_TOKEN')
    return{
      mdiArchiveSearch, mdiCheckCircle, mdiCreation,
      showProductsIndex: false,
      warrantyCheckbox: false,
      id: 0,
      postTemplateTitle: this.templateTitle,
      postTitle: this.title,
      postSubtitle: this.subtitle,
      postChars: this.chars,
      postDescription: this.description,
      postPromoCode: this.promoCode,
      postInstruction: this.instruction,
      postDelivery: this.delivery,
      postPrice: this.price,
      postOtherPrice: this.otherPrice,
      postFullPrice: this.fullPrice,
      postLink: this.link,
      postAliLink: this.aliLink,
      postShop: this.shop,
      postPostId: this.postId,
      postRating: this.rating,
      postPartnerId: this.partnerId,
      postChannel: this.channel,
      postWarranty: this.warranty,
      postDeadline: this.deadline,
      postErrors: this.errors,
      postPrices: this.prices,
      postErid: '',
      postAepCreativeId: null,
      searchString: '',
      searchItems: [],
      searchIsLoading: false,
      imageUrl: '',
      imageKey: 1, // если захотели добавить рамку к товару - это будет сделано на сервере, и нужно будет перезагрузить компонент v-img. это произойдёт при смене imageKey
      dropzoneOptions: {
        url: 'https://backend.telestatic.ru/upload/product?id=0',
        thumbnailWidth: 150,
        maxFilesize: 100,
        headers: { Authorization: `Bearer ${token}` }
      },
      cropImg: '',
      shops: [],
      aliLinkLoading: false,
      deliveryOptions:[
        {value: '🇷🇺Экспресс-доставка из РФ <b>курьером бесплатно</b>.', text: '🇷🇺Экспресс-доставка из РФ курьером бесплатно.'},
        {value: '🇷🇺Экспресс-доставка из РФ.', text: '🇷🇺Экспресс-доставка из РФ.'},
        {value: '🇷🇺Экспресс-доставка из РФ. Есть доставка курьером за <b>49₽</b>.', text: '🇷🇺Экспресс-доставка из РФ. Есть доставка курьером за 49₽.'},
        {value: 'Бесплатная доставка <b>курьером</b>.', text: 'Бесплатная доставка курьером.'}
        // {value: '🇷🇺Экспресс-доставка Plus из РФ.', text: 'Экспресс РФ'},
        // {value: '🇷🇺Экспресс-доставка Plus из РФ <b>курьером</b>.', text: 'Экспресс РФ курьер'},
        // {value: '🇷🇺Экспресс-доставка Plus из РФ <b>курьером</b> (нужно выбрать в корзине).', text: 'Экспресс РФ курьер (корзина)'},
        // {value: '🇷🇺Экспресс-доставка из РФ <b>курьером бесплатно</b>.', text: 'Экспресс РФ курьер бесплатно'},
        // {value: '🇷🇺Экспресс-доставка из РФ.', text: '🇷🇺Экспресс-доставка из РФ.'},
        // {value: '🇷🇺Экспресс-доставка Plus из РФ.\n\nЕсть доставка <b>курьером</b> за 49₽ (нужно выбрать в корзине).', text: 'Экспресс РФ курьер 49'},
        // {value: '🇷🇺Доставка из РФ.', text: 'Доставка из РФ'},
        // {value: 'Ускоренная доставка Plus.', text: 'Ускоренная'},
        // {value: 'Бесплатная доставка <b>курьером</b>.', text: 'Бесплатная курьером'},
        // {value: 'Доставки нет, только <b>самовывоз</b>.', text: 'Самовывоз'},
      ],
      partners: [
        {id: 1, title: 'AE Platform', disabled: false},
        {id: 2, title: 'EPN', disabled: false},
        {id: 3, title: 'Admitad', disabled: false},
        {id: 4, title: 'Без партнёрки', disabled: false}
      ],
      postLinkReadonly: false, //флаг можно ли редактировать ссылку,
      files: [], //для тестов вставки картинки из копипаста, удалить
      images: [], //для тестов вставки картинки из копипаста, удалить

    }
  },
  computed:{
    needUpdateProduct(){
      return this.$store.getters.needUpdateProduct
    },
    needSaveProduct(){
      return this.$store.getters.needSaveProduct
    },
    channels(){
      return this.$store.state.channels
    },
    linkLocked(){
      if (this.postPartnerId === 1) {return true}
      return false
    },
  },
  watch:{
    needUpdateProduct(v){
      if (v){
        this.save()
      }
    },
    needSaveProduct(v){
      if (v){
        this.save()
        this.$store.dispatch('needSaveProduct', false)
      }
    },
    searchString(v){
      console.log('search string: ', v)
      try {
        // eslint-disable-next-line no-unused-vars
        const url = new URL(v)
        this.searchString = this.cleanLink(v)
        this.postTitle = this.cleanLink(v)
      } catch (error) {
        console.log('cannot parse url: ', error)
      }
      if (this.id > 0) return
      if (this.searchIsLoading) return
      if (!v) return
      this.searchIsLoading = true
      this.debSearchProduct()
    },
    postTemplateTitle(v){
      this.$emit('update:templateTitle', v)
      this.debUpdate()
    },
    postTitle(v){
      this.$emit('update:title', v)
      this.debUpdate()
    },
    postSubtitle(v){
      this.$emit('update:subtitle', v)
      this.debUpdate()
    },
    postDescription(v){
      this.$emit('update:description', v)
      this.debUpdate()
    },
    postChars(v){
      this.$emit('update:chars', v)
      this.debUpdate()
    },
    postPromoCode(v){
      this.$emit('update:promoCode', v)
      this.debUpdate()
    },
    postInstruction(v){
      this.$emit('update:instruction', v)
      this.debUpdate()
    },
    postDelivery(v){
      this.$emit('update:delivery', v)
      this.debUpdate()
    },
    postPrice(v){
      this.$emit('update:price', v)
      this.debUpdate()
    },
    postOtherPrice(v){
      this.$emit('update:otherPrice', v)
      this.debUpdate()
    },
    postFullPrice(v){
      this.$emit('update:fullPrice', v)
      this.debUpdate()
    },
    postLink(v){
      this.$emit('update:link', v)
      this.debUpdate()
    },
    postShop(v){
      console.log('SHOP UPDATED! V: ', v)
      if (_.has(v, 'id')){
        this.postLinkReadonly=false
        _.each(this.partners, x => {x.disabled = false}) //сначала всех включим, и отключим всё что неьзя
        if (v.id === 1 || v.id === 2) { //ali
          //если в this.finalText нет строки "ИНН 7703380158"

          if (!this.finalText.includes('ИНН 7703380158')){
            this.finalText = this.finalText + '\n\nРеклама ООО АЛИБАБА.КОМ (РУ) ИНН 7703380158'
          }
          this.partners.find(x=>x.id===2).disabled = true
          this.partners.find(x=>x.id===3).disabled = true
          this.postPartnerId = 1
          this.postLinkReadonly=true
        }
        else if (v.id===3 || v.id===4 || v.id===5){ //ситилинк эльдорадо яндекс маркет
          this.partners.find(x=>x.id===1).disabled = true
          // this.partners.find(x=>x.id===3).disabled = true
          this.postPartnerId = 3
        }
        else if (v.id===6){ //мвидео
          this.partners.find(x=>x.id===1).disabled = true
          this.partners.find(x=>x.id===2).disabled = true
          this.postPartnerId = 3
        }else if (v.id===7){ //мтс
          this.partners.find(x=>x.id===1).disabled = true
          this.postPartnerId = 2
        }else if(v.id === 8) { //8 - озон
          this.partners.find(x=>x.id===1).disabled = true
          this.partners.find(x=>x.id===2).disabled = true
          this.partners.find(x=>x.id===3).disabled = true
          this.postPartnerId = 4
        }else if (v.admitad_id > 0){
          this.partners.find(x=>x.id===1).disabled = true
          this.partners.find(x=>x.id===2).disabled = false
          this.partners.find(x=>x.id===3).disabled = false
          this.postPartnerId = 3
        }
      }
      this.$emit('update:shop', v)
      this.$emit('needUpdateResultPost', true)
      this.debUpdate()
    },
    postPartnerId(v){
      this.$emit('update:partnerId', v)
      this.debUpdate()
    },
    postPostId(v){
      this.$emit('update:postId', v)
    },
    postAliLink(v){
      this.$emit('update:aliLink', v)
      try {
        const url = new URL(v)
        console.log(url)
        if (url.host === 'aliexpress.ru'){
          this.postShop = this.shops.find(x=>x.id===1)
        } else if (url.host === 'www.citilink.ru'){
          this.postShop = this.shops.find(x=>x.id===3)
        } else if (url.host === 'www.eldorado.ru'){
          this.postShop = this.shops.find(x=>x.id===4)
        } else if (url.host === 'market.yandex.ru'){
          this.postShop = this.shops.find(x=>x.id===5)
        } else if (url.host === 'www.mvideo.ru'){
          this.postShop = this.shops.find(x=>x.id===6)
        } else if (url.host === 'shop.mts.ru'){
          this.postShop = this.shops.find(x=>x.id===7)
        } else if (url.host === 'www.ozon.ru'){
          this.postShop = this.shops.find(x=>x.id===8)
        }
      } catch (error) {
      }
      //тут ещё немного магии UX
      this.debUpdate()
    },
    postRating(v){
      this.$emit('update:rating', v)
      this.debUpdate()
    },
    postChannel(v){
      this.$emit('update:channel', v)
    },
    postWarranty(v){
      if (v.includes('Официальная гарантия')) {
        this.warrantyCheckbox = true
      }
      this.$emit('update:warranty', v)
    },
    postDeadline(v){
      this.$emit('update:deadline', v)
    },
    postErrors(v){
      this.$emit('update:errors', v)
    },
    postErid(v){
      //если в строке v есть ?erid=, то нужно оставить только то, что после ?erid
      if (v.includes('?erid=')){
        v = v.split('?erid=')[1]
      }
      this.postErid = v
      console.log('setting product erid:', v)
      this.$emit('update:erid', v)
    },
    postAepCreativeId(v){
      this.$emit('update:aepCreativeId', v)
    },
    imageUrl(v){
      this.$emit('update:image', v)
      if (!_.isEmpty(v)){
        this.postErrors = this.postErrors.filter(x=>x.err !== 'img')
      }else{
        this.postErrors.push({err: 'img', text: 'Не загружена картинка'})
      }
      this.$emit('update:errors', this.postErrors)
      this.debUpdate()
    },
    id(v){
      this.postErrors = this.postErrors.filter(x=>x.err !== 'notSaved')
      // this.postErrors.splice(this.postErrors.findIndex(x=>x.err==='notSaved'), 1) //так удалять вообще-то было неправильно, лол
      this.$emit('update:errors', this.postErrors)
      this.$emit('update:productId', v)
    },
    links(v){
      if (v.length>0){
        if (!_.isEmpty(v[0].link)){
          const errors = this.postErrors.filter(x=>x.err !== 'noLinks')
          this.$emit('update:errors', errors)
          // this.postErrors.splice(this.postErrors.findIndex(x=>x.err==='noLinks'), 1) //это удаление - неправильное удаление
        }
      }else{
        this.postErrors.push({err: 'noLinks', text: 'Не заданы ссылки'})
      }
      this.$emit('update:errors', this.postErrors)
      this.debUpdate()
    },
    postPrices(v){
      console.log('update: postPrices', v)
      this.$emit('update:prices', v)
      this.debUpdate() // почему-то этого не было, хотя ведь нужно
    }
  },
  created() {
    this.$store.dispatch('getShops').then(res=>{
      this.shops = res
    })
  },
  methods: {
    debSearchProduct:_.debounce(function () {
      this.searchProduct()
    }, 500),
    debUpdate:_.debounce(function (){
      this.update()
    }, 500),
    debSave:_.debounce(function(){
      this.save()
    }, 500),
    update() {// значение изменилось - нужно заставить компонент ResultPost перерендерить (через vuex)
      this.$store.dispatch('needUpdateResultPost', true)
    },
    createProduct(){ //вызывается из кнопки, когда при поиске в v-combobox ничего не нашлось
      console.log('gonna create product...')
      try {
        // eslint-disable-next-line no-unused-vars
        const url = new URL(this.searchString)
        this.postAliLink = this.cleanLink(this.searchString)
        this.postTitle = 'Новый товар ' + Date.now()
        this.postTemplateTitle = `Новый товар ${Date.now()}`
        this.debSave()
        this.links[0].link = this.postAliLink
        this.links[0].title = 'Товар'

        console.log('dat is valid URL!!!')
      } catch (error) {
        console.log('ERRORR!!!!!!!! ', this.postTitle, ' is not valid url!!!')
      }
      this.postTitle = 'Новый товар ' + Date.now()
      this.debSave()
      this.$emit('productLoaded')
    },
    searchProduct(){// поиск продукта в комбобоксе
      httpClient.get('/products/search?search='+this.searchString+'&expand=shop').then(({data}) => {
        this.searchItems = data
        this.searchIsLoading = false
      })
    },
    selectProduct(item){// в комбобоксе выбрали продукт - нужно подставить все данные в data
      console.log('item selected: ', item)
      this.id = item.id
      this.postTemplateTitle = item.template_title
      this.postTitle = item.title
      this.postSubtitle = item.subtitle
      this.postDescription = item.description
      this.postInstruction = item.instruction
      this.postDelivery = item.delivery
      this.postChars = item.chars
      this.postPrice = item.price
      this.postFullPrice = item.full_price
      this.postOtherPrice = item.other_price
      this.postPromoCode = item.promo_code
      this.imageUrl = item.image
      this.postLink = item.link
      this.postShop = item.shop
      this.postAliLink = item.ali_link
      this.postRating = item.rating
      this.postWarranty = item.warranty
      this.postDeadline = item.deadline
      // this.finalText = item.final_text
      if (item.other_prices !== null){
        this.postPrices = item.other_prices
      }
      this.links[0].link = item.ali_link
      this.links[0].title = 'Основной продукт'
      this.links[0].type = 'primary'
      this.$refs.links.checkLinks()
      this.$store.dispatch('canUpdateResultText', true)
      this.$store.dispatch('needUpdateResultPost', true)
      // this.$emit('update:finalText', item.final_text)
      // this.$emit('updateFinalText', item.final_text)
      // console.log('setting final text: ', item.final_text)
      this.$emit('productLoaded')
      // this.$refs.dropzone.setOption('url', 'https://backend.telestatic.ru/upload/product?id=' + this.id)
    },
    clean(str) {
      if (!str) return ''
      str = _.replace(str, new RegExp('•', 'g'), '')
      str = _.replace(str, new RegExp(' {2}', 'g'), ' ')
      str = this.trim(str)
      return str
    },
    cleanPromoCode(str){
      str = _.trim(str)
      str = _.replace(str, '  ', ' ')
      // str = _.toUpper(str)
      return str
    },
    cleanPrice(price){
      console.log('price before clean: ', price)
      price = price.replace(/[^\d.-]/g, '')
      console.log('price after clean: ', price)
      return price
    },
    cleanLink(link){
      const arr = link.split('?')
      return arr[0]
    },
    trim(str){
      const ar = str.split('\n')
      let trimmed = ''
      _.each(ar, (s, i) => {
        if (s){
          s = _.trim(s)
          if (i>0){
            trimmed += '\n'
          }
          s = s[0].toUpperCase() + s.slice(1) // _.capitalize делает все остальные символы с маленькой буквы, а всякие WI-FI надо оставлять как есть
          trimmed += s
        }
      })
      return trimmed
    },
    save(){
      const item = {title: this.title, template_title: this.templateTitle, subtitle: this.subtitle, chars: this.chars, description: this.description,
        promo_code: this.promoCode, instruction: this.instruction, price: this.price, full_price: this.fullPrice, other_price: this.otherPrice,
        link: this.link, final_text: this.finalText, delivery: this.delivery, ali_link: this.aliLink, rating: this.rating, warranty: this.warranty, deadline: this.deadline,
        other_prices: this.prices
      }
      if (_.has(this.shop, 'name')){
        item.shop = this.shop.name
        item.shop_id = this.shop.id
      }
      if (this.id < 1){// делаем POST, создаем новый продукт
        httpClient.post('products', item).then(({data}) => {
          this.$toast('Новый продукт создан')
          if (_.has(data, 'id')){
            this.id = data.id
          }
        })
      }else{
        item.id = this.id
        httpClient.put('products/' + this.id, item).then(({data}) => {
          this.$toast('Продукт обновлён')
          if (_.has(data, 'id')){
            this.id = data.id
          }
        })
      }
    },
    copyProduct(){
      this.id = 0
      this.postTitle= '(copied) ' + this.title
      this.postLink = ''
      this.postAliLink = ''
      this.imageUrl = ''
      this.$toast('Копируем продукт...')
      this.save()
    },
    uploadSuccess(file, response){
      this.imageUrl = response
    },
    updateDZHeaders(){
      const url = `https://backend.telestatic.ru/upload/product?id=${this.id}`
      this.$refs.dropzone.setOption('url', url)
    },
    reloadImage(url){
      this.imageUrl = url
      this.imageKey++
    },
    requestScreenshot(){
      httpClient.get(`products/screenshot?product_id=${this.id}&raw_url=${this.postAliLink}`).then(({data})=>{
        console.log('screenshot created, data: ', data)
        this.imageUrl = data.url
      })
    },
    onPaste(pasteEvent, callback){ // дикая хуйня, которую я откуда-то скопипастил, не трогать!!!
      if(pasteEvent.clipboardData === false){
        if(typeof (callback) === 'function'){
          console.log('Undefined ')
          callback(undefined)
        }
      }
      var items = pasteEvent.clipboardData.items
      if(items === undefined){
        if(typeof (callback) === 'function'){
          callback(undefined)
          console.log('Undefined 2')
        }
      }
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') === -1) continue
        var blob = items[i].getAsFile()
        this.addImage(blob)
      }
    },
    addImage(file){ // не менее дикая хуйня, которую я откуда-то скопипастил, не трогать!!!
      if (!file.type.match('image.*')) {
        // eslint-disable-next-line promise/param-names
        return new Promise((reject) => {
          const error = {
            message: 'Solo puede arrastrar imágenes.',
            response: {
              status: 200
            }
          }
          this.$obtenerError(error)
          reject()

        })
      }

      this.files.push(file)
      // eslint-disable-next-line no-unused-vars
      const img = new Image()
      const reader = new FileReader()
      reader.onload = (e) => this.images.push(e.target.result)
      // eslint-disable-next-line no-unused-vars
      const str = JSON.stringify(file)

      reader.readAsDataURL(file)
      this.debUpload()
    },
    debUpload:_.debounce(function(){
      this.uploadImg()
    }, 500),
    uploadImg(){
      this.$toast('Загружаю картинку...')
      console.log('FILEL', this.images[0])
      httpClient.post('upload/product-clipboard?id='+this.productId, {image: this.images[0]}, {
        headers: {
          // 'Content-Type': 'application/json'
        }
      }).then((res)=>{
        console.log('res: ', res)
        this.imageUrl = res.data
        this.images = []
        // this.reloadImage()
      })
    },
    showProductTemplates(product){
      this.$store.dispatch('setShowProduct', {show: true, product})
    }
  },
}
</script>

