<template>
	<v-dialog
			v-model="dialog"
			transition="dialog-bottom-transition"
			min-width="1600"
			min-heigth="800"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
					@click="dialog=true"
					color="primary"
					dark
					v-bind="attrs"
					v-on="on"
			>
				Обрезать
			</v-btn>
		</template>
		<v-container class="grey lighten-5">
					<v-row align="center" justify="space-around">
						<v-col cols="12">
							<v-card>
								<v-card-text>
									<vue-cropper
											min-width="90%"
											ref="cropper"
											:key="cropImg"
											:movable="false"
											:scalable="false"
											:zoomable="false"
											:zoomOnWheel="false"
											:auto-crop="false"
											:src="'https://backend.telestatic.ru/upload/show?product_id=' + id"
									>
									</vue-cropper>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
					<v-row align="center" justify="space-around">
						<v-col>
							<v-card>
								<v-card-text>
									<v-checkbox
											v-model="addFrame"
											label="Ебануть рамку"
											class="shrink mr-2 mt-0"
									></v-checkbox>
									<v-btn
											color="primary"
											@click.prevent="cropImage"
									>
										Обрезать
									</v-btn>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
		</v-container>
	</v-dialog>
</template>

<script>
	import VueCropper from 'vue-cropperjs'
	import 'cropperjs/dist/cropper.css'
	import httpClient from '../../services/http.service'
	export default {
		name: 'Cropper',
		components:{
			VueCropper,
		},
		props:{
			id: Number,
		},
		data () {
			return {
				addFrame: false,
				dialog: false,
				cropImg: '',
				resultImage: '',
			}
		},

		methods:{
			cropImage() {
				// get image data for post processing, e.g. upload or setting image src
				this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
				this.uploadImage()
			},
			uploadImage(){
				this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
					const formData = new FormData()
					formData.append('file', blob, 'name.png')
					this.$toast('Обновляем картинку...', {
						timeout: 2000
					})
					httpClient
						.post('/upload/product?id=' + this.id+'&framize=' + this.addFrame, formData)
						.then(({ data }) => {
							console.log('response: ', data)
							this.$emit('uploaded', data)
							// this.imageUrl = data
							// this.imageKey++

						})
						.catch(function (error) {
							console.log(error)
						})
				}, '')
			},
		}
	}
</script>

<style scoped>

</style>
